import { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import api from "../../../config/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth";
import { useContext } from "react";
import ImageUpload from "../../../components/ImageUpload";
import { storagePhotos } from "../../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";

const Form = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [title, setTitle] = useState(""); // State to hold the selected category title
  const [buttonName, setButtonName] = useState("");
  const [linkBanner, setLinkBanner] = useState("");
  const [loading, setLoading] = useState(false);
  const [valuesInitial, setValuesInitial] = useState();

  useEffect(() => {
    async function getCardBonus() {
      await api
        .get("/bonus/card", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((response) => {
          const array = response.data;
          console.log(array);
          array.forEach((element) => {
            if (element.id === id) {
              setValuesInitial(element);
              setTitle(element.title);
              setButtonName(element.button_name);
              setLinkBanner(element.link_youtube);
            }
          });
        })
        .catch((error) => {
          console.error("Não foi encontrado...", error);
        });
    }
    getCardBonus();
  }, [id, user.token]);

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
      } else {
        alert("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };
  async function handleUpload() {
    setLoading(true);
    let imageUrl; 
    if (uploadedImage) {
      const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
      try {
        await uploadBytes(storageRef, uploadedImage);
        imageUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error("Erro no upload: ", error);
        toast.error("Erro ao fazer upload da imagem");
        setLoading(false);
        return;
      }
    } else {
      imageUrl = valuesInitial.banner;
    }

    try {
      await api.put(
        "/bonus/card/update",
        {
          id: id,
          banner: imageUrl,
          title: title,
          link_youtube: linkBanner,
          button_name: buttonName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      toast.success("Card atualizado com sucesso!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.log(error.message);
      toast.info("Carregamento em andamento... tente novamente");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box m="20px">
      <Header
        title="Tela de editar o bônus"
        subtitle="Editando a tela de Bônus"
      />
      {valuesInitial ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={valuesInitial.banner}
            alt=""
            style={{
              maxWidth: "200px",
              maxHeight: "300px",
              borderRadius: "10px",
            }}
          />
        </div>
      ) : null}
      <Box m="20px"> </Box>
      <Formik onSubmit={() => {}} validationSchema={checkoutSchema}>
        {({ handleBlur }) => (
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                styles={{ width: "100%", backgroundColor: "red" }}
              >
                <ImageUpload onImageUpload={handleImageUpload} />
                {uploadedImage && (
                  <Box>
                    <Typography variant="body2">
                      {uploadedImage.name}
                    </Typography>{" "}
                    <img
                      src={URL.createObjectURL(uploadedImage)}
                      alt="Uploaded"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </Box>
                )}
              </Box>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Título"
                onBlur={handleBlur}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                name="titulo"
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Link do Banner"
                onBlur={handleBlur}
                onChange={(e) => setLinkBanner(e.target.value)}
                value={linkBanner}
                name="link_youtube"
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Título do Banner"
                onBlur={handleBlur}
                onChange={(e) => setButtonName(e.target.value)}
                value={buttonName}
                name="button_name"
                sx={{ gridColumn: "span 4" }}
              />

            </Box>
            <Box display="flex" justifyContent="center" mt="30px">
              <Button
                onClick={() => handleUpload()}
                color="secondary"
                variant="contained"
              >
                {loading ? "Carregando..." : "Atualizar Card"}
              </Button>
            </Box>
            <Box display="flex" justifyContent="center" mt="60px"></Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  titulo: yup.string().required("Título é obrigatório"),
  subtitulo: yup.string().required("Subtítulo é obrigatório"),
  imagemBanner: yup.string().required("Imagem do Banner é obrigatória"),
  tituloBanner: yup.string().required("Título do Banner é obrigatório"),
  linkBanner: yup.string().required("Link do Banner é obrigatório"),
});


export default Form;
