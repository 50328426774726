import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Header from "../../../components/Header";
import api from "../../../config/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../../components/BannerUpload";
import { storagePhotos } from "../../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";

const StyledButton = styled.button`
  background-color: #14b1a4;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: arial, sans-serif;
  font-weight: bold;
  &:hover {
    background-color: #14b1a4;
  }
`;

const Classes = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [available, setAvailable] = useState(true); // Initialize with false for "Não"
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(""); // State to hold the selected category title
  const { user } = useContext(AuthContext);
  const [classesCategory, setClassesCategory] = useState([]);
  const [linkBanner, setLinkBanner] = useState("");
  const [loading, setLoading] = useState(false);
  const [valuesInitial, setValuesInitial] = useState();

  useEffect(() => {
    async function getGame() {
      try {
        const response = await api.get("/classes/", {
          headers: {
            'Authorization': 'Bearer ' + user.token
          }
        });
        const classes = response.data;

        classes.forEach((element) => {
          if (element.id === id) {
            setValuesInitial(element);
            setLinkBanner(element.link_youtube);
            setSelectedCategory(element.classeCategory);
            setImageUrl(element.banner)
          }
        });
      } catch (error) {
        console.error("Erro ao buscar jogo:", error);
      }
    }

    getGame(id);
  }, [id, user.token]);


  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
      } else {
        alert("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      let urlFoto;
  
      if (uploadedImage) {
        const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
        await uploadBytes(storageRef, uploadedImage);
        urlFoto = await getDownloadURL(storageRef);
      } else {
        urlFoto = imageUrl || valuesInitial.banner;
      }
  
      if (!urlFoto) {
        toast.error("Imagem não carregada, tente novamente...");
        setLoading(false);
        return;
      }
  
      const requestData = {
        id: id,
        banner: urlFoto,
        link_youtube: linkBanner,
        classeCategory: selectedCategory,
        available: available,
      };
  
      await api.put("/classes/update", requestData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }).then(() => {
        toast.success("Aula atualizada com sucesso!");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })

    } catch (error) {
      console.error("Erro na solicitação PUT:", error);
      toast.error("Erro ao atualizar a aula");
      setLoading(false);
    }
  };
  

  useEffect(() => {
    async function getClassesCategory() {
      try {
        const response = await api.get("/classesCategory", {
          headers: {
            'Authorization': 'Bearer ' + user.token
          }
        });
        setClassesCategory(response.data);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    }
    getClassesCategory();
  }, [user.token]);

  const handleBanner = (event) => {
    setLinkBanner(event.target.value);
  };

  const handleNewGameClick = () => {
    navigate("/classes");
  };


  return (
    <Box m="20px">
      <Header title="Editar Aulas" subtitle="Edite as aulas" />
      <StyledButton onClick={handleNewGameClick}>VOLTAR</StyledButton>


      <Box mt="20px"></Box>
      <Formik
        onSubmit={()=>{}}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          handleBlur,
        }) => (
          <form>
            <FormControl fullWidth>
              <InputLabel>Categoria que deseja inserir</InputLabel>
              <Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)} // Update selected category
              >
                {classesCategory.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt="20px"></Box>

            <FormControl fullWidth margin="normal">
              <InputLabel id="include-in-home-label">Liberar aula? </InputLabel>
              <Select
                labelId="include-in-home-label"
                id="include-in-home-select"
                value={available} // Use selectPro state
                onChange={(e) => setAvailable(e.target.value)} // Update selectPro state
              >
                <MenuItem value={true}>Sim</MenuItem> {/* Set the value to 1 for "Sim" */}
                <MenuItem value={false}>Não</MenuItem> {/* Set the value to 0 for "Não" */}
              </Select>
            </FormControl>
            <Box mt="20px"></Box>

            {/* Banner */}
            <Box display="flex" alignItems="center" gap={2}>
              {imageUrl ? (
                <img src={imageUrl} alt="Banner" style={{ maxWidth: "100px", maxHeight: "100px" }} />
              ) : uploadedImage ? (
                <Box>
                  <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt="Uploaded"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </Box>
              ) : null}

              <ImageUpload onImageUpload={handleImageUpload} />
              {uploadedImage && (
                <Box>
                  <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt="Uploaded"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </Box>
              )}
            </Box>

            <br></br>

            {/* Video Link */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Link da Aula"
              onBlur={handleBlur}
              onChange={handleBanner}
              value={linkBanner}
              name="videoLink"
              sx={{ gridColumn: "span 4" }}
            />

            <Box display="flex" justifyContent="end" mt="20px">
              <Button onClick={handleUpload} color="secondary" variant="contained">
                {loading ? "Carregando..." : "Atualizar"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Define the validation schema and initial values
const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  telefone: yup
    .string().min(11).max(11)
    .matches(phoneRegExp, "Número de telefone inválido")
    .required("Telefone é obrigatório"),
  videoLink: yup.string().required("Link da Aula é obrigatório"),
});

const initialValues = {
  email: "",
  telefone: "",
  videoLink: "",
  nome: "",
};

export default Classes;
