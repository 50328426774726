import styled from "styled-components";


export const Container = styled.div`
  width: 50vw;
  border: 10px;
  height: 100vh; /* Defina a altura máxima desejada */
  margin-top: -40px; 
  overflow-y: absolute;
  overflow-x: hidden;
  backdrop-filter: blur(5px);
  align-items: center;

  margin-left: 20%;
  @media (max-width: 1100px) {
    /* Quando a largura da tela for menor ou igual a 768px (tamanho de celular), ajuste o tamanho do plano de fundo */
  padding-left: 20%;
  padding-right: 20%;  
  }
  @media (max-width: 768px) {
    /* Quando a largura da tela for menor ou igual a 768px (tamanho de celular), ajuste o tamanho do plano de fundo */
  padding-left: 0%;
  padding-right: 0%;  
  }
`;

export const Titulo = styled.h1`
  color: #fff;
`;
