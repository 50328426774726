import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: #14b1a4;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: arial, sans-serif;
  font-weight: bold;
  &:hover {
    background-color: #14b1a4;
  }
`;