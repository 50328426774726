//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO

import { Routes, Route } from "react-router-dom";

import Login from "../../src/scenes/login/index";

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Login />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
