import React, { useContext, useEffect, useState } from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { tokens } from "../../theme";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../../components/Header";
import GeographyChart from "../../components/GeographyChart";
import StatBox from "../../components/StatBox";
import { AuthContext } from "../../context/auth";
import api from "../../config/api";
import StarRateIcon from "@mui/icons-material/StarRate";
import VerifiedIcon from "@mui/icons-material/Verified";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [users, setUsers] = useState([]);
  const [usersPro, setUsersPro] = useState([]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function getsDatas() {
      const res = await api.get("/users/", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUsers(res.data);
    }

    async function getsDatasPremium() {
      const res = await api.get("/premium/", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUsersPro(res.data);
    }

    getsDatas();
    getsDatasPremium();
  }, [user.token]);

  const calculateRevenue = () => {
    const normalUserPrice = 9.9;
    const proUserPrice = 97.9;
    const totalUsers = users.length;
    const totalProUsers = usersPro.length;
    const normalUsersRevenue = (totalUsers - totalProUsers) * normalUserPrice;
    const proUsersRevenue = totalProUsers * proUserPrice;
    const totalRevenue = normalUsersRevenue + proUsersRevenue;

    // Usar toFixed para formatar o valor com 2 casas decimais
    return totalRevenue.toFixed(2);
  };

  // Dados para o gráfico de barras
  const barChartData = {
    labels: ['Total', 'Usuários Pro', 'Usuários Normal'],
    datasets: [
      {
        label: 'Total',
        data: [users.length, usersPro.length, users.length - usersPro.length],
        backgroundColor: [
          'rgba(53, 162, 235, 0.5)',
          'rgba(255, 99, 132, 0.5)',
          'rgba(75, 192, 192, 0.5)'
        ],
        borderColor: [
          'rgba(53, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Bem-Vindo ao DashBoard da NeoBet" />
        <Box>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(9, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            visualizar={false}
            title={users && users.length}
            subtitle="Total de usuários"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            visualizar={true}
            title={usersPro && usersPro.length}
            subtitle="Total de usuários pro"
            increase={
              users && usersPro
                ? `${Math.round(
                  (usersPro.length / users.length) * 100
                )} % `
                : <Typography variant="body2">Carregando...</Typography>
            }
            icon={
              <VerifiedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "24px", marginLeft: "-6px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={calculateRevenue()}
            subtitle="Total de Vendas"
            icon={
              <MonetizationOnIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Últimos usuários
            </Typography>
          </Box>

          {users && users.map((element, i) => (
            <Box
              key={`${element.email}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {element.name}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {element.email}
                </Typography>
              </Box>

              <Box>{new Date(element.created_at).toLocaleString()}</Box>


            </Box>
          ))}

        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Gráfico de Vendas
          </Typography>
          <Box height="250px" mt="-20px">
            <Bar data={barChartData} options={options} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Localização de Compras
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
