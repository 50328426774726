import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";

import { Link } from "react-router-dom";



const Container = styled.div`
  width: 180px;
  height: 300px;
  margin: 10px;
  border-radius: 10px;
  border: 2px solid #14b1a4;
`;

const Header = styled.div``;

const Analyst = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const AnalystImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  object-fit: cover;
  margin: 0px 5px;
`;

const AnalystName = styled.span`
  font-weight: 600;
  color: #14b1a4;
`;




const Latest = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
  border-bottom: 1px solid #14b1a4;
  padding: 5px;
`;






const UserMainPro = styled.div`
  width: 90%;
`;

const BannerGamerPro = styled.div`
  position: relative;
  display: flex; // Adiciona display flex
  justify-content: center; // Centraliza horizontalmente
  margin-left: 9px;
  align-items: center; // Centraliza verticalmente
  width: 100%;

`;

const ImageGamePro = styled.img`
  width: 100%; // Mantenha a largura em 100%
  display: block;
  border-radius: 10px;
  object-fit: cover; // Isso fará a imagem cobrir o espaço disponível
`;
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;
  border-radius: 10px;
`;

const ButtonEntrarPro = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
`;
const ButtonEntrarBntPro = styled(Link)`
  text-transform: uppercase;
  width: 95%;
  height: 40px;
  margin: 5px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #14b1a4;
  color: black;
  text-decoration: none; // Remove o sublinhado do link
`;


const Cards = ({
  Pro,
  AnalystPhoto,
  NameAnalyst,
  Players,
  NumberGreen,
  NumberRed,
  CardImg,
  LinkGame, // A URL para redirecionar ao editar
  onDeleteGame
}) => {
  const handleDeleteClick = () => {
    onDeleteGame(); // Chama a função onDeleteGame quando o botão for clicado
  };

  return (
    <Container>
      <Header>
        <Latest>

        </Latest>
      </Header>


      <UserMainPro>
        <BannerGamerPro>
          <ImageGamePro src={CardImg} alt="" />
          <ImageOverlay />
        </BannerGamerPro>
        <ButtonEntrarPro>
          <ButtonEntrarBntPro to={LinkGame}>EDITAR JOGO</ButtonEntrarBntPro>
        </ButtonEntrarPro>
        <ButtonEntrarPro>
        <ButtonEntrarBntPro onClick={handleDeleteClick}>EXCLUIR JOGO</ButtonEntrarBntPro>
        </ButtonEntrarPro>
      </UserMainPro>
    
    </Container>
  );
};

export default Cards;