import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";


const OptionsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const EditIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  color: #ff0000;
  font-size: 24px;
`;

const BannerOptions = ({ onEditClick, onDeleteClick, bannerId }) => {
  const handleEditClick = () => {
    onEditClick(bannerId);
  };

  const handleDeleteClick = () => {
    onDeleteClick(bannerId);
  };

  return (
    <OptionsContainer>
      <EditIcon icon={faEdit} onClick={() => onEditClick(bannerId)} />
      <DeleteIcon icon={faTrash} onClick={handleDeleteClick} />
    </OptionsContainer>
  );
};

export default BannerOptions; // Exporte o componente como padrão
