import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header";
import ImageUpload from "../../../components/ImageUpload";

import { AuthContext } from "../../../context/auth";

import { storagePhotos } from "../../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import api from "../../../config/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Form = () => {
  const { id } = useParams();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryNumber, setSelectedCategoryNumber] = useState(0);
  const [selectedGame, setSelectedGame] = useState("");

  const [showForm, setShowForm] = useState(true); // Novo estado para controlar a exibição do formulário
  const [currentUrl, setCurrentUrl] = useState(""); // Estado para o URL atual
  const [iframeUrl, setIframeUrl] = useState(""); // Estado para o URL do iFrame

  const [uploadedImage, setUploadedImage] = useState(null); // Estado para armazenar a imagem carregada
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valuesInitial, setValuesInitial] = useState();
  const { user } = useContext(AuthContext);
  const [includeInHomeValue, setIncludeInHomeValue] = useState(0); // Inicialize com 0 para "Não"
  const [analyst, setAnalyst] = useState([]);
  const [selectedAnalyst, setSelectedAnalyst] = useState(""); // Create a state variable for selected analyst
  const [selectPro, setSelectPro] = useState(false); // Initialize with false for "Não"

  useEffect(() => {
    async function getGame() {
      try {
        await api.get("/games/",
        {
          headers: {
          'Authorization': 'Bearer ' + user.token
        }}).then((response) => {
          const games = response.data;

          games.forEach((element) => {
            if (element.id === id) {
              setValuesInitial(element);

              switch (element.category) {
                case 1:
                  setSelectedCategory("Cassino");
                  break;
                case 2:
                  setSelectedCategory("Crash");
                  break;
                case 3:
                  setSelectedCategory("Slots");
                  break;

                default:
              }
                   
               setSelectPro(element.all)
              setSelectedGame(element.name);
              setIncludeInHomeValue(element.home);
              setSelectedAnalyst(element.analyst_id.id)
              setIframeUrl(element.link_iframe)
            }
          });
        });
      } catch (error) {
        console.error("Erro ao buscar jogo:", error);
      }
    }

    getGame(id);
  }, [id]);

  useEffect(() => {
    async function getAnalysts() {
      try {
        await api.get("/analyst", {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }).then((response) => {
          setAnalyst(response.data);
        });
      } catch (error) {
        console.error("Erro ao buscar Analista:", error);
      }
    }

    getAnalysts(id);
  }, [id, user.token]);

  // Função atualizada para lidar com o upload da imagem
  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
        //setImageUrl(URL.createObjectURL(e.target.files[0]));
      } else {
        alert("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };

 async function handleUpload() {
  setLoading(true);

  let urlFoto;

  if (uploadedImage) {
    // Se uma nova imagem foi carregada, faça o upload
    const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
    try {
      await uploadBytes(storageRef, uploadedImage);
      urlFoto = await getDownloadURL(storageRef);
    } catch (error) {
      console.error("Erro no upload: ", error);
      toast.error("Erro ao fazer upload da imagem");
      setLoading(false);
      return;
    }
  } else {
    urlFoto = valuesInitial.gameImg;
  }

  try {
    if (!urlFoto) {
      toast.info("Imagem Carregando, aguarde um instante...");
      return;
    }

    switch (selectedCategory) {
      case "Crash":
        setSelectedCategoryNumber(2);
        break;

      case "Cassino":
        setSelectedCategoryNumber(1);
        break;

      case "Slots":
        setSelectedCategoryNumber(3);
        break;

      default:
    }

    await api.post("/games/update", {
      id: id,
      all: selectPro,
      gameImg: `${urlFoto}`,
      name: selectedGame,
      home: includeInHomeValue,
      link_iframe: iframeUrl,
      analyst: selectedAnalyst, // Pass the selected analyst here
      category: selectedCategoryNumber
    },
    {
      headers: {
      'Authorization': 'Bearer ' + user.token
   }})
    .then((response) => {
      toast.success("Jogo Atualizado com sucesso!");
      window.location.reload();
    }); 

  } catch (error) {
    console.error("Erro ao atualizar o jogo: ", error);
    toast.info("Carregamento em andamento... tente novamente");
  } finally {
    setLoading(false);
  }
}
  // async function handleSave() {
  //   try {
  //     await handleUpload();

  //     if (!imageUrl) {
  //       toast.info("Imagem Carregando, aguarde um instante...");
  //     } else {
  //       switch (selectedCategory) {
  //         case "Crash":
  //           setSelectedCategoryNumber(2);
  //           break;

  //         case "Cassino":
  //           setSelectedCategoryNumber(1);
  //           break;

  //         case "Slots":
  //           setSelectedCategoryNumber(3);
  //           break;

  //         default:
  //       }

  //       await api
  //         .post("/games", {
  //           gameImg: `${imageUrl}`,
  //           name: selectedGame,
  //           index: 0,
  //           link_iframe: iframeUrl,
  //           category: selectedCategoryNumber,
  //         },
  //         {
  //           headers: {
  //           'Authorization': 'Bearer ' + user.token
  //     }})
  //         .then((response) => {
  //           toast.success("Imagem atualizada");
  //         });
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }

  // Função para atualizar o URL atual
  const handleCurrentUrlChange = (event) => {
    setCurrentUrl(event.target.value);
  };

  // Função para atualizar o URL do iFrame
  const handleIframeUrlChange = (event) => {
    setIframeUrl(event.target.value);
  };

  const handleFilterClick = () => {
    setShowForm(true); // Atualiza o estado para exibir o formulário
  };

  // Dados das categorias e jogos
  const categories = {
    Crash: [
      "Aviator",
      "Aviator2x",
      "AviatorVelasAltas",
      "Spaceman",
      "Spaceman2x",
      "SpacemanVelasAltas",
    ],
    Cassino: [
      "Roleta",
      "BacBo",
      "Dragon Tiger",
      "Crazy Time",
      "Football Studio",
    ],
    Slots: [
      "Mines",
      "Fortune Tiger",
      "Fortune Rabbit",
      "Fortune OX",
      "Fortune Mouse",
    ],
  };
  // console.log(selectedCategory)
  // console.log(selectedGame)
  const menuProps = {
    PaperProps: {
      style: {
        backgroundColor: "white",
        color: "black", // Adicione isso para garantir que o texto seja visível
      },
    },
  };

  const selectStyles = {
    // Estilos para o texto e ícone dentro do Select
    color: "white",
    "& .MuiSelect-icon": {
      color: "white", // ou qualquer outra cor que seja visível em seu tema
    },
    // Estilos para o contorno quando o Select está focado
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white", // ou qualquer outra cor visível
    },
    // Estilos para o texto do item selecionado
    "& .MuiSelect-select": {
      color: "white", // Isso deve garantir que o texto seja sempre branco
    },
    // Estilos para os itens do menu
    "& .MuiMenuItem-root": {
      color: "black", // Isso deve garantir que o texto seja sempre preto no menu dropdown
    },
  };

  // Funções para manipular mudanças
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedGame(""); // Reseta a seleção do jogo quando a categoria muda
  };

  const handleGameChange = (event) => {
    setSelectedGame(event.target.value);
  };

  return (
    <Box m="20px">
      <Header
        title="ATUALIZANDO JOGOS"
        subtitle="Atualize os jogos de uma maneira bem mais simples"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="category-select-label">Categorias</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={selectedCategory}
          label="Categorias"
          onChange={handleCategoryChange}
          MenuProps={menuProps} // Usa os MenuProps para o estilo do menu
          sx={selectStyles} // E o sx para os outros estilos
        >
          {Object.keys(categories).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" disabled={!selectedCategory}>
        <InputLabel
          htmlFor="game-select"
          style={{ visibility: selectedGame ? "hidden" : "visible" }}
        >
          Nome do Jogo
        </InputLabel>
        <TextField
          id="game-select"
          value={selectedGame}
          label=""
          onChange={(e) => setSelectedGame(e.target.value)}
        />
         <FormControl fullWidth margin="normal">
          <InputLabel id="category-select-label">Selecione o Analista</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedAnalyst} // Use selectedAnalyst state
            onChange={(e) => setSelectedAnalyst(e.target.value)} // Update selectedAnalyst state
          >
            {analyst.map((analystItem) => (
              <MenuItem key={analystItem.id} value={analystItem.id}>
                {analystItem.name} ({analystItem.game})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="include-in-home-label">Game disponivel para todos?</InputLabel>
          <Select
            labelId="include-in-home-label"
            id="include-in-home-select"
            value={selectPro?selectPro:false} // Use selectPro state
            onChange={(e) => setSelectPro(e.target.value)} // Update selectPro state
          >
            <MenuItem value={true}>Sim</MenuItem> {/* Set the value to 1 for "Sim" */}
            <MenuItem value={false}>Não</MenuItem> {/* Set the value to 0 for "Não" */}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="include-in-home-label">Adicionar jogo na home?</InputLabel>
          <Select
            labelId="include-in-home-label"
            id="include-in-home-select"
            value={includeInHomeValue} // Use includeInHomeValue
            onChange={(e) => setIncludeInHomeValue(e.target.value)} // Atualize includeInHomeValue
          >
            <MenuItem value={1}>Sim</MenuItem> {/* Defina o valor como 1 para "Sim" */}
            <MenuItem value={0}>Não</MenuItem> {/* Defina o valor como 0 para "Não" */}
          </Select>
        </FormControl>

      </FormControl>

      

      {valuesInitial ? (
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img src={valuesInitial.gameImg} alt="" style={{
          maxWidth: "100px", 
          maxHeight: "100px",
          borderRadius: '10px',
        }} />
      </div>
      ) : null}

      {showForm && (
        <Box mt="20px">
          <Box display="flex" alignItems="center" gap={2}>
            <ImageUpload onImageUpload={handleImageUpload} />
            {uploadedImage && (
              <Box>
                <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
                {/* Exibe o nome do arquivo */}
                {/* Exibe a imagem */}
                <img
                  src={URL.createObjectURL(uploadedImage)}
                  alt="Uploaded"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              </Box>
            )}
          </Box>

          <FormControl fullWidth margin="normal">
            <TextField
              fullWidth
              variant="outlined"
              //label="URL Atual"
              value={valuesInitial && valuesInitial.link_iframe}
              disabled
              onChange={handleCurrentUrlChange}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              fullWidth
              variant="outlined"
              label={`Atualizar URL iFrame [${selectedGame}]`} // Inclui o nome do jogo selecionado no rótulo
              value={iframeUrl}
              onChange={handleIframeUrlChange}
            />
            <Box display="flex" justifyContent="center" mt="20px">
              <Button
                onClick={() => handleUpload()}
                color="secondary"
                variant="contained"
              >
                {loading ? "Carregando..." : "Cadastrar"}
              </Button>
            </Box>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default Form;