import styled from "styled-components";

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

export const StyledButton = styled.button`
  background-color: #14b1a4;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: arial, sans-serif;
  font-weight: bold;
  &:hover {
    background-color: #14b1a4;
  }
`;

export const CardWrapper = styled.div`
  width: calc(33.33% - 20px);
  margin: 10px;
  padding: 0 5%;
  @media (max-width: 600px) {
    width: calc(50% - 20px);
  }
`;