import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import backCassino from "../../assets/user.png";
import backgroundImg from "../../assets/BackGround.png";

import {AuthContext} from "../../context/auth";
import { toast } from 'react-toastify';

import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Altura total da tela */
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden; /* Isso evita a rolagem */
  padding: 20px;
`;


const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  padding: 20px;
  border-radius: 25px;
  width: 400px;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.1);
`;

const FormInput = styled.input`
  padding: .4rem 2rem .4rem 2rem;
  border: 2px solid transparent;
  border-bottom-color: #999;
  outline: none;
  background-color: transparent;
  font-size: 15px;
  font-family: 'Poppins-Regular', sans-serif;
  transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 83%; /* Faz o input ocupar a largura total do container */
  &:focus {
    border-bottom-color: #000;
  }
`;

const FormInputContainer = styled.div`
  position: relative;
  margin: 10px 0;
`;

const FormInputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  color: #333;
  font-size: 18px;
`;

const FormButton = styled.button`
  padding: 14px 20px;
  border: none;
  margin-top: 20px;
  font-size: 17px;
  color: #fff;
  border-radius: 20px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  position: relative;
  background: #a64bf4;
  background: -webkit-linear-gradient(right, #90ffe1, #1d9c80, #90ffe1, #1d9c80);
  background: -o-linear-gradient(right, #90ffe1, #1d9c80, #90ffe1, #1d9c80);
  background: -moz-linear-gradient(right, #90ffe1, #1d9c80, #90ffe1, #1d9c80);
  background: linear-gradient(to right, #90ffe1, #1d9c80);
  
  &:hover {
    box-shadow: 0 0 3px #90ffe1,
                0 0 6px #90ffe1,
                0 0 12px #90ffe1,
                0 0 20px #90ffe1;
                 background: linear-gradient(to right, #1d9c80, #90ffe1); // Inverte as cores do degradê no hover
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #00dbde, #fc00ff);
    z-index: -1;
    transition: left 0.4s;
  }
  &:hover::before {
    left: 100%;
  }
`;

const FormLabel = styled.label`
  font-size: 16px;
  margin-top: 20px;
  font-family: 'Poppins-Regular', sans-serif;
  color: #333;
  margin-bottom: 5px;
`;

const FormTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif; /* ou outra fonte de sua preferência */
  background: linear-gradient(to left, #004d40, #009688, #004d40, #009688); /* tons de verde do print */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s forwards infinite linear;

  @keyframes shine {
    0% { filter: brightness(1); }
    50% { filter: brightness(1.5); }
    100% { filter: brightness(1); }
  }
`;



const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
`;

const BannerContainerIMG = styled.img`
  height: auto;
  width: 200px;
  object-fit: cover;
`;

export function validarEmail(email) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
}

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [eyeIcon, setEyeIcon] = useState(FaEye); // Inicialmente, use o ícone do olho aberto

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setEyeIcon(showPassword ? FaEye : FaEyeSlash); // Alterna o ícone do olho
  };

  const { login } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email.trim() !== '' && password.trim() !== '') {
      if(validarEmail(email)){
        login(email, password);
      } else {
        toast.error('Digite um e-mail válido');
      
      }
    } else {
      toast.error('Você não concluiu todos os campos de login');
    }
  };


  return (
    <LoginContainer>
      <LoginForm onSubmit={handleLogin}>
        <BannerContainer>
          <BannerContainerIMG src={backCassino} alt="Logo Cassino" />
        </BannerContainer>
        <FormTitle>Painel Administrativo</FormTitle>
        <FormLabel>Email</FormLabel>
        <FormInputContainer>
          <FormInputIcon>
            <FaUser />
          </FormInputIcon>
          <FormInput
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu E-mail"
          />
        </FormInputContainer>
        <FormLabel>Senha</FormLabel>
        <FormInputContainer>
          <FormInputIcon>
            <FaLock />
          </FormInputIcon>
          {showPassword ? (
            <FaEyeSlash
              style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px', color: '#333', fontSize: '18px', cursor: 'pointer' }}
              onClick={togglePasswordVisibility}
            />
          ) : (
            <FaEye
              style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px', color: '#333', fontSize: '18px', cursor: 'pointer' }}
              onClick={togglePasswordVisibility}
            />
          )}
          <FormInput
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua Senha"
          />
        </FormInputContainer>
        <FormButton type="submit" className="login100-form-bgbtn">Entrar</FormButton>
      </LoginForm>
    </LoginContainer>
  );
}

export default Login;