import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import api from "../../config/api";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth";
import { useContext } from "react";
import { storagePhotos } from "../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ImageUpload from "../../components/BannerUpload";
import { useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom"

const StyledButton = styled.button`
  background-color: #14b1a4;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: arial, sans-serif;
  font-weight: bold;
  &:hover {
    background-color: #14b1a4;
  }
`;
const HomeApp = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {user} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [valuesInitial, setValuesInitial] = useState();
  const [linkBanner, setLinkBanner] = useState("");
  const {id}=useParams();

  const handleNewGameClick = () => {
    navigate("/bannersHome");
  };
  const navigate = useNavigate();
  const handleFormSubmit = async (values) => {
    try {
      const response = await api.post("/users", {
        email: values.email,
        name: values.nome,
        telefone: values.telefone,
      },
      {
        headers: {
        'Authorization': 'Bearer ' + user.token
      }
    });
      toast.success("Banner Adicionado.");
    } catch (error) {
      if (error.response) {
        // Server responded with an error status code
        console.error("Server Error:", error.response.data);
        toast.error("Erro ao cadastrar usuário. Verifique os dados e tente novamente.");
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No Response:", error.request);
        toast.error("Não foi possível conectar ao servidor. Tente novamente mais tarde.");
      } else {
        // Something else happened
        console.error("Error:", error.message);
        toast.error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
      }
    }
  };

  const handleUpload = async () => {
    try {
      let urlFoto;
  
      if (uploadedImage) {
        // Se uma nova imagem foi carregada, faça o upload
        const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
        await uploadBytes(storageRef, uploadedImage);
        urlFoto = await getDownloadURL(storageRef);
      } else {
        // Se não, use a URL da imagem existente
        urlFoto = imageUrl || valuesInitial.banner;
      }
  
      if (!urlFoto) {
        toast.info("Imagem Carregando, aguarde um instante...");
        return;
      }
  
      // Os dados que você está enviando na solicitação PUT
      const requestData = {
        id: id,
        banner: urlFoto,
        link: linkBanner
      };
  
      // Faz a solicitação PUT para atualizar o recurso
      await api.put("/home/update", requestData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
  
      toast.success("Aula atualizada com sucesso!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Erro na solicitação PUT:", error);
      toast.error("Erro ao atualizar a aula");
      setLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
      } else {
        alert("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };
  const handleBanner = (event) => {
    setLinkBanner(event.target.value);
  };
  useEffect(() => {
    async function getGame() {
      try {
        const response = await api.get("/home", {
          headers: {
            'Authorization': 'Bearer ' + user.token
          }
        });
        const classes = response.data;

        classes.forEach((element) => {
          if (element.id === id) {
            setValuesInitial(element);
            setLinkBanner(element.link);
            setImageUrl(element.banner)
          }
        });
      } catch (error) {
        console.error("Erro ao buscar jogo:", error);
      }
    }

    getGame(id);
  }, [id]);

  return (
    <Box m="20px">
      <Header title="Atualizar Banners" subtitle="Atualizando banners da home" />
      <StyledButton onClick={handleNewGameClick}>BANNERS DA HOME</StyledButton>
      <Box mt="20px"></Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            
              {/* Banner */}
              <Box display="flex" alignItems="center" gap={2}>
              {imageUrl ? (
                <img src={imageUrl} alt="Banner" style={{ maxWidth: "100px", maxHeight: "100px" }} />
              ) : uploadedImage ? (
                <Box>
                  <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt="Uploaded"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </Box>
              ) : null}
              
                  <ImageUpload onImageUpload={handleImageUpload} />
                  {uploadedImage && (
                  <Box>
                      <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
                      <img
                          src={URL.createObjectURL(uploadedImage)}
                          alt="Uploaded"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                  </Box>
                  )}
              </Box>

              <br></br>

            {/* Link */}
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Link de Redirecionamento do Banner"
                onBlur={handleBlur}
                onChange={handleBanner}
                value={linkBanner}
                name="nome"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>


            <Box display="flex" justifyContent="end" mt="20px">
              <Button onClick={()=> (handleUpload())} color="secondary" variant="contained">
                Atualizar
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  telefone: yup
    .string().min(11).max(11)
    .matches(phoneRegExp, "Número de telefone inválido")
    .required("Telefone é obrigatório"),
});
const initialValues = {
  email: "",
  telefone: "",
  nome: "",
};

export default HomeApp;
