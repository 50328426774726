import Routes from "./routes/";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import AuthProvider from "./context/auth";

function App() {
  return (
    <AuthProvider>
      <Routes />
      <ToastContainer autoClose={3000} />
    </AuthProvider>
  );
}

export default App;