import styled, { createGlobalStyle } from "styled-components";


export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh; /* Defina a altura máxima desejada */
  overflow: auto; /* Adicione uma barra de rolagem quando necessário */
  padding-left: 10%;
  padding-bottom: 70px; 
  padding-right: 30%;
  @media (max-width: 768px) {
    /* Quando a largura da tela for menor ou igual a 768px (tamanho de celular), ajuste o tamanho do plano de fundo */
    padding: 10px;
    background-size: 100%;
    background-position: center;
  }
`;


export const Banner = styled.div`
  background: green;
  height: 200px;
`;

export const AdditionalText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  color: #fff;
  font-family: ubuntu, Sans-serif;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 80px;
  text-transform: uppercase;
  line-height: 1.3em;
  z-index: 2;
  @media (max-width: 768px) {
    padding: 1px;
    text-align: center;
    background-size: 100%;
    background-position: center;
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7px;
  margin-top: 5px;
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  width: 100%;
  max-height: 600px;
  min-height: 1px;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

export const BannerItem = styled.div`
  flex: 0 0 auto;
  scroll-snap-align: start;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
`;

export const BannerImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 130px;
  border-radius: 10px;
  margin-left: 5px;
`;


export const EmailTextContainer = styled.div`
  color: #14b1a4;
  font-family: Verdana, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  text-align: center;
`;
export const Input = styled.input`
  width: 50%;
  padding: 10px;
  border: 1px solid #14b1a4; /* Cor da borda verde da Neobet */
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  margin-right: 10px; 
  align-items: center;
  justify-content: center;
`;
export const InputText = styled.input`

  width: 30%;
  padding: 5px;
  border: 1px solid #14b1a4; /* Cor da borda verde da Neobet */
  border-radius: 10px;
  display: flex;
  font-size: 16px;
  margin-right: 10px; 
  align-items: center;
  justify-content: center;
`;
export const Button = styled.button`
  background-color: #14b1a4; /* Cor verde da Neobet */
  color: white;
  padding: 10px 20px;
  border: none;
  margin-right: 10px; 
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #128f7e; /* Cor verde mais escura ao passar o mouse */
  }
`;
export const ButtonFechar = styled.button`
  background-color: #14b1a4; /* Cor verde da Neobet */
  color: white;
  padding: 5px 10px;
  border: none;
  margin-right: 10px; 
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #128f7e; /* Cor verde mais escura ao passar o mouse */
  }
`;

export const Center = styled.div`
  display: flex;
  align-items:center;
  background-color: #fff;
  width: 100%;
  margin: 20px 0;  
`;

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
`;

export const CategoryName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #DCDDDF; /* Cor de texto desejada */
  margin: 10px 0px;
  display: flex;
  align-items: center;
`;

export const EditIcon = styled.div`
  font-size: 20px;
  color: #007bff; /* Cor do ícone de edição desejada */
  margin-left: 10px;
  cursor: pointer;

  /* Estilos de hover (quando o mouse passa por cima) */
  &:hover {
    color: #0056b3; /* Cor de destaque desejada */
  }
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  color: white;
  padding: 5px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #fff;
  margin: 5px 0;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonDiv = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  background-color: #14b1a4;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: arial, sans-serif;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    background-color: #14b1a4;
  }
`;
// Import the JSON file containing category titles