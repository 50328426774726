import React, { useContext } from "react";
import { AuthContext } from "../context/auth";
import App from "./app.routes";
import Auth from "./auth.routes";
import Loading from "../components/Loading";

export default function Index() {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }

  return user ? <App /> : <Auth />;
}
