import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ImageUpload = ({ onImageUpload }) => {
    return (
        <Box
            sx={{
                border: '1px dashed grey',
                borderRadius: '5px',
                padding: '26px',
                textAlign: 'center',
                cursor: 'pointer',
                width: '100%', // This will make the width of the element to expand to its parent's width
                display: 'flex', // Enables the flexbox model
                justifyContent: 'center', // Horizontally centers the children (the icon and text)
                alignItems: 'center', // Vertically centers the children
                flexDirection: 'column' // Stacks the children vertically
            }}
            onClick={() => document.getElementById('image-upload').click()}
        >
            <input
                accept="image/*"
                id="image-upload"
                type="file"
                hidden
                onChange={onImageUpload}
            />
            <CloudUploadIcon />
            <Typography variant="body2">Imagem do Jogo</Typography>
        </Box>
    );
};

export default ImageUpload;