// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBsEH3lp8fsnjoZ1lvm3qZ4Gu2AKQoTgzk",
    authDomain: "api-estrela.firebaseapp.com",
    projectId: "api-estrela",
    storageBucket: "api-estrela.appspot.com",
    messagingSenderId: "299298202950",
    appId: "1:299298202950:web:0d3d2f84d23efe6ecff147"
};
const firebaseapp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseapp);
const auth = getAuth(firebaseapp);
const storagePhotos = getStorage(firebaseapp);

export { db, auth, storagePhotos }; 
