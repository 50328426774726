//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO
import { useState } from "react";
import Sidebar from "../scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../theme";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../scenes/dashboard";
import Team from "../scenes/team";
import Invoices from "../scenes/invoices";
import Contacts from "../scenes/contacts";
import Bar from "../scenes/bar";
import Form from "../scenes/newUser";
import Line from "../scenes/line";
import Pie from "../scenes/pie";
import FAQ from "../scenes/faq";
import Iframes from "../scenes/iframes/iframes";
import Geography from "../scenes/geography";

import HomeApp from "../scenes/homeApp";
import BannerHome from "../scenes/bannerHome";
import EditarHome from "../scenes/updateHome";

//Games
import UpdateGames from "../scenes/games/updateGames";
import Games from "../scenes/games/homeGame";
import NewGame from "../scenes/games/newGame/";

//Classes 
import NewClasses from "../scenes/classes/newClasses";
import UpdateClasses from "../scenes/classes/updateClasses";
import Classes from "../scenes/classes/classes";

//Bonus 
import Bonus from "../scenes/bonnus/homeBonus";
import NewBonus from "../scenes/bonnus/newBonus";
import UpdateBonus from "../scenes/bonnus/updateBonus";

export default function AppRoutes() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/iframes" element={<Iframes />} />
              <Route path="/updategames/:id" element={<UpdateGames />} />
              <Route path="/games" element={<Games />} />
              <Route path="/newgame" element={<NewGame />} />
              <Route path="/telaHome" element={<HomeApp />} />
              <Route path="/newclasses" element={<NewClasses />} />
              <Route path="/classes" element={<Classes />} />
              <Route path="/updateClasses/:id" element={<UpdateClasses />} />
              <Route path="/bannersHome" element={<BannerHome />} />
              <Route path="/updateHome/:id" element={<EditarHome />} />
              <Route path="/bonus" element={<Bonus />} />
              <Route path="/newbonus" element={<NewBonus />} />
              <Route path="/updatebonus/:id" element={<UpdateBonus />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

// return (

//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <div className="app">
//           <Sidebar isSidebar={isSidebar} />
//           <main className="content">
//             <Topbar setIsSidebar={setIsSidebar} />
//             <Routes>

//             </Routes>
//           </main>
//         </div>
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
