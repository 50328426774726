import React, { useEffect, useContext, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Container } from "./styles";
import { toast } from "react-toastify";
import gestao from '../../../assets/Tutorial/Gestao.png';
import quebraPadrao from '../../../assets/Tutorial/THUMB_TELEGRAM.png';
import { useNavigate } from "react-router-dom";
import {
  Box
} from "@mui/material";
import { AuthContext } from "../../../context/auth";
import api from "../../../config/api";
import BannerOptions from "./bannerOptions";

const GradientRectangle = styled.div`
    background: linear-gradient(to bottom, #14b1a4, rgba(187, 240, 39, 0));
    border-radius: 15px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    max-width: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adiciona sombra escura */
    margin-bottom: 70px; /* Espaçamento entre os containers */
  `;

const Banner = styled.img`
    max-width: 100%;
    max-height: 90%;
    width: auto;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adiciona sombra escura */
  `;

const BannerText = styled.div`
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 10px; /* Espaçamento entre o banner e o texto */
    text-align: center; /* Centralizando o texto */
    font-family: ubuntu, Sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    z-index: 2;

  `;

const GlobalStyle = createGlobalStyle`
      body {
        overflow: hidden;
      }
      ::-webkit-scrollbar {
        width: 0 !important;
      }
      ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
      }
    `;

const DivButton = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
`;

const ButtonLuis = styled.button`
display: flex;
justify-content: center;
align-items: center;
border-bottom-left-radius: 25px;
border-top-right-radius: 25px;
border: 1px solid #14b1a4;
width: 40%;
height: 40px; 
margin-bottom: 20px;
cursor: pointer;
text-transform: uppercase;
background-image: linear-gradient(to right, #14b1a4,#14b1a4,#2D5AE0, #14b1a4);
box-shadow: 3px -3px #2D5AE0; 
transition: all .2s ease-in-out;
margin-top: 20px;
&&{
  transform: scale(1.1); 
}
`;
const ButtonLuisTxt = styled.span`
display: flex;
justify-content: center;
align-items: center;
color: #fff;
font-size: 18px;
font-weight: bold;
text-shadow: 1px 1px 10px  black;
`;

const EditableTitle = styled.h1`
color: white;
font-family: "Verdana", Geneva, sans-serif;
font-size: 16px;
font-weight: 400;
z-index: 2;
text-align: center;
cursor: pointer;

input {
  /* Estilos para o input quando em modo de edição */
  color: white;
  font-family: "Verdana", Geneva, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  border: none;
  text-align: center;
  border-bottom: 2px solid #14b1a4;
  margin-top: 4px;
  padding: 0;
}
`;

const EditableSubtitle = styled.p`
  color: #14b1a4;
  font-family: "Verdana", Geneva, sans-serif;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  text-align: center;
  cursor: pointer;

  input {
    /* Estilos para o input quando em modo de edição */
    color: #14b1a4;
    font-family: "Verdana", Geneva, sans-serif;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    border: none;
    text-align: center;
    border-bottom: 2px solid #14b1a4;
    margin-top: 4px;
    padding: 0;
  }
`;

export const StyledButton = styled.button`
    background-color: #14b1a4;
    color: black;
    padding: 10px 20px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: arial, sans-serif;
    font-weight: bold;
    margin-right: 10px;
    margin-left: 10px;
    &:hover {
      background-color: #14b1a4;
    }
  `;
const BannerWrapper = styled.div`
  position: relative;
  &:hover > div {
    opacity: 5; // Isso garante que o BannerOptions se torne visível quando o mouse estiver sobre o BannerWrapper
  }
  position: relative;
`;
export const Input = styled.input`
  width: 50%;
  padding: 10px;
  border: 1px solid #14b1a4; /* Cor da borda verde da Neobet */
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  margin-right: 10px; 
  align-items: center;
  justify-content: center;
`;

const Tutorial = () => {
  const { user } = useContext(AuthContext);


  const navigate = useNavigate();

  const [bonus, setBonus] = useState([]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedSubtitle, setEditedSubtitle] = useState("");
  const [cards, setCards] = useState([]);
  const [activeBannerIndex, setActiveBannerIndex] = useState(null); // Estado para rastrear o banner ativo
  
  const handleNewGameClick = () => {
    navigate("/newbonus");
  };
  // Função para mostrar as opções de editar e excluir para um banner específico
  const showBannerOptions = (index) => {
    setActiveBannerIndex(index);
  };

  // Função para ocultar as opções de editar e excluir para um banner específico
  const hideBannerOptions = () => {
    setActiveBannerIndex(null);
  };

  // Adicione um estado para rastrear se o mouse está sobre um banner
  const [isHovered, setIsHovered] = useState(false);
  const [banners, setBanners] = useState([
    { id: 'banner1', imageUrl: gestao },
    { id: 'banner2', imageUrl: quebraPadrao }
  ]);
  const [bannerOptionsVisible, setBannerOptionsVisible] = useState([]); // Estado para controlar as opções de banner
  // Funções para lidar com os cliques dos botões
  const handleCreateAccountClick = (bannerId) => {
    console.log('Create account clicked for:', bannerId);
  };

  const handleTelegramClick = (bannerId) => {
    console.log('Telegram clicked for:', bannerId);
  };
  const [id, setId] = useState(); // Estado para controlar o titulo id


  // O estado deve inicializar todos os banners como não visíveis
  useEffect(() => {
    setBannerOptionsVisible(new Array(banners.length).fill(false));
  }, [banners.length]);

  // Dentro do seu componente Tutorial
  const handleMouseEnter = (index) => {
    setIsHovered(true); // Adicione este estado para rastrear o hover
    setBannerOptionsVisible(bannerOptionsVisible.map((item, i) => i === index ? true : item));
  };

  const handleMouseLeave = () => {
    setIsHovered(false); // Atualize o estado quando o mouse não está mais sobre o elemento
    setBannerOptionsVisible(bannerOptionsVisible.map(() => false));
  };


  // Adicione uma função para manipular o evento de clique do ícone de edição
  const handleEdit = () => {
    console.log("Edit clicked");
  };

  // Adicione uma função para manipular o evento de clique do ícone de exclusão
  const handleDelete = () => {
    console.log("Delete clicked");
  };


  useEffect(() => {
    async function getBonus() {
      await api.get("/bonus", {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }).then((response) => {
        if (response.data.length > 0) {
          setEditedTitle(response.data[0].title);
          setEditedSubtitle(response.data[0].subtitle);
          setId(response.data[0].id)
        }
        setBonus(response.data);
      })
    }
    getBonus()
  }, [user.token]);

  useEffect(() => {
    async function bonusCard() {
      await api.get("/bonus/card", {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }).then((response) => {
        setCards(response.data);
      })
    }
    bonusCard()
  }, [user.token]);

  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleSubtitleClick = () => {
    setIsEditingSubtitle(true);
  };

  const handleTitleChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleSubtitleChange = (event) => {
    setEditedSubtitle(event.target.value);
  };
  async function getExistingTitlesAndSubtitles() {
    try {
      const response = await api.get("/bonus/", {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });

      if (response.data && response.data.length > 0) {
        const existingTitles = response.data.map((item) => item.title);
        const existingSubtitles = response.data.map((item) => item.subtitle);
        return { existingTitles, existingSubtitles };
      }

      return { existingTitles: [], existingSubtitles: [] };
    } catch (error) {
      console.error("Erro ao obter os títulos e subtítulos existentes", error);
      return { existingTitles: [], existingSubtitles: [] };
    }
  }

  const handleTitleSave = async () => {
    setIsEditingTitle(false);

    if (editedTitle.trim() === "") { // Verifique se o título está vazio ou apenas contém espaços em branco
      toast.error("O título não pode estar vazio!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);

      return;
    }

    // Obtenha os títulos existentes
    const { existingTitles } = await getExistingTitlesAndSubtitles();

    if (existingTitles.includes(editedTitle)) {
      return;
    }

    try {
      await api.put("/bonus/update", {
        id: id,
        title: editedTitle,
        subtitle: editedSubtitle,
      }, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      toast.success("Título atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar o título.");
    }
  };

  const handleSubtitleSave = async () => {
    setIsEditingSubtitle(false);

    // Obtenha os subtítulos existentes
    const { existingSubtitles } = await getExistingTitlesAndSubtitles();

    if (editedSubtitle.trim() === "") { // Verifique se o subtítulo está vazio ou apenas contém espaços em branco
      toast.error("O subtítulo não pode estar vazio!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);

      return;
    }

    if (existingSubtitles.includes(editedSubtitle)) {
      return;
    }

    try {
      await api.put("/bonus/update", {
        id: id,
        title: editedTitle,
        subtitle: editedSubtitle,
      }, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      });
      toast.success("Subtítulo atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar o subtítulo.");
    }
  };


  async function deleteCard(id) {
    try {
      await api.delete("/bonus/card/delete", {
        data: { id: id }, // Passa o ID no corpo da requisição
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      }).then((response) => {
        toast.success("deletado com sucesso!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
    } catch (error) {
      console.error(`Erro ao deletar banner com ID ${id}: ${error.message}`);
    }
  }
  function handleEditCard(id) {
    navigate(`/updatebonus/${id}`)
  }

  return (
    <>
      <GlobalStyle />
      <Box mt="20px" />
      <StyledButton onClick={handleNewGameClick}>Adicionar Bônus</StyledButton>

      <Container>


        {bonus && bonus.length > 0 && (
          <>
            {isEditingTitle ? (
              <EditableTitle>
                <Input
                  type="text"
                  value={editedTitle}
                  onChange={handleTitleChange}
                  onBlur={handleTitleSave}
                  autoFocus
                />
              </EditableTitle>
            ) : (
              <EditableTitle onClick={handleTitleClick()}>
                {editedTitle}
              </EditableTitle>
            )}

            {isEditingSubtitle ? (
              <EditableSubtitle>
                <Input
                  type="text"
                  value={editedSubtitle}
                  onChange={handleSubtitleChange}
                  onBlur={handleSubtitleSave}
                  autoFocus
                />
              </EditableSubtitle>
            ) : (
              <EditableSubtitle onClick={handleSubtitleClick}>
                {editedSubtitle}
              </EditableSubtitle>
            )}
          </>
        )}
        {cards.map((element, index) => (
          <GradientRectangle key={element.id}>
            <BannerWrapper
              onMouseEnter={() => showBannerOptions(index)}
              onMouseLeave={hideBannerOptions}
            >
              <Banner src={element.banner} alt="Banner" />
              {activeBannerIndex === index && (
                <BannerOptions
                  onEditClick={() => handleEditCard(element.id)}
                  onDeleteClick={() => deleteCard(element.id)}
                  bannerId={element.id}
                />
              )}
              <BannerText>{element.title}</BannerText>
              <DivButton>
                <ButtonLuis>
                  <a style={{ textDecoration: 'none' }} href={element.link_youtube} about="ex"><ButtonLuisTxt>{element.button_name}</ButtonLuisTxt></a>
                </ButtonLuis>
              </DivButton>
            </BannerWrapper>
          </GradientRectangle>
        ))}
      </Container>
    </>
  );
}

export default Tutorial;
