import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import api from "../../../config/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth";
import { useContext } from "react";
import ImageUpload from "../../../components/ImageUpload";
import { storagePhotos } from "../../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useContext(AuthContext);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [title, setTitle] = useState(""); // State to hold the selected category title
  const [buttonName, setButtonName] = useState("");
  const [linkBanner, setLinkBanner] = useState("");
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
      } else {
        alert("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };
  async function handleUpload() {
    setLoading(true);

    if (uploadedImage) {
      const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
      await uploadBytes(storageRef, uploadedImage).then(async (snapshot) => {
        await getDownloadURL(ref(storagePhotos, `images/${uploadedImage.name}`))
          .then(async (url) => {
            try {
              if (
                url === "" ||
                title === "" ||
                linkBanner === "" ||
                buttonName === ""
              ) {
                toast.info("Preencha todos os campos!");
                setLoading(false);
              } else {
                await api
                  .post(
                    "/bonus/card",
                    {
                      banner: url,
                      title: title,
                      link_youtube: linkBanner,
                      button_name: buttonName,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + user.token,
                      },
                    }
                  )
                  .then((response) => {
                    toast.success("Card adicionado com sucesso!");
                    setLoading(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  })
                  .catch((error) => {
                    toast.warning(
                      "Não é possível cadastrar mais de 2 banners!"
                    );
                    setLoading(false);
                    console.error(error);
                  });
              }
            } catch (error) {
              console.log(error.message);
              toast.error("Erro ao cadastrar bônus... tente novamente");
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      toast.error("Insira um banner");
      setLoading(false);
    }
  }
  return (
    <Box m="20px">
      <Header title="Tela de bônus" subtitle="Criando as telas de Bônus" />

      <Formik
        onSubmit={() => {}}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ handleBlur, handleSubmit }) => (
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Título */}
              <Box display="flex" alignItems="center" gap={2}>
                <ImageUpload onImageUpload={handleImageUpload} />
                {uploadedImage && (
                  <Box>
                    <Typography variant="body2">
                      {uploadedImage.name}
                    </Typography>{" "}
                    <img
                      src={URL.createObjectURL(uploadedImage)}
                      alt="Uploaded"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </Box>
                )}
              </Box>

              {/* titulo */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Título"
                onBlur={handleBlur}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                name="titulo"
                sx={{ gridColumn: "span 4" }}
              />

              {/* Imagem do Banner */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Link do Banner"
                onBlur={handleBlur}
                onChange={(e) => setLinkBanner(e.target.value)}
                value={linkBanner}
                name="link_youtube"
                sx={{ gridColumn: "span 4" }}
              />

              {/* Título do Banner */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Título do Banner"
                onBlur={handleBlur}
                onChange={(e) => setButtonName(e.target.value)}
                value={buttonName}
                name="button_name"
                sx={{ gridColumn: "span 4" }}
              />
              {/* Banner */}

              <br></br>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                onClick={() => handleUpload()}
                type="submit"
                color="secondary"
                variant="contained"
              >
                {loading ? "Carregando..." : "Criar Card"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  titulo: yup.string().required("Título é obrigatório"),
  subtitulo: yup.string().required("Subtítulo é obrigatório"),
  imagemBanner: yup.string().required("Imagem do Banner é obrigatória"),
  tituloBanner: yup.string().required("Título do Banner é obrigatório"),
  linkBanner: yup.string().required("Link do Banner é obrigatório"),
});

const initialValues = {
  titulo: "",
  link_youtube: "",
  button_name: "",
};

export default Form;
