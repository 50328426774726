import React, { useEffect, useState, useContext } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { useHistory } from "react-router-dom";
import api from "../../config/api";

import { toast } from "react-toastify";
import { timeout } from "q";
import { AuthContext } from "../../context/auth";
import { Switch, FormControlLabel } from "@mui/material";

const Form = () => {
  const { user } = useContext(AuthContext);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  useEffect(() => {
    async function getLive() {
      await api
        .get("/lives", {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setLive(response.data);
        });
    }
    getLive();
  }, [user.token]);

  const [live, setLive] = useState("");

  const handleFormSubmit = async (values) => {

    try {
      await api.put(
        "/lives/update",
        {
          id: live[0].id,
          link_youtube: values.urlYoutube,
          link_game: values.urlGame,
          available: isChecked
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      toast.success("Live Atualizada com sucesso!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Erro na solicitação PUT:", error);
    } 
  };

  const a = live ? `${live[0].link_youtube}` : "";

  const initialValues = {
    urlYoutube: a,
    urlGame: "",
  };

  const [isChecked, setIsChecked] = useState(false); // Estado para controlar o valor do Switch

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked); // Atualiza o estado com o novo valor do Switch
  };

  return (
    <Box m="20px">
      <Header
        title="CADASTROS LIVES"
        subtitle="Cadastrando Iframes das Lives!"
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="URL Atual"
                onBlur={handleBlur}
                value={live ? live[0].link_youtube : values.urlYoutube}
                name="urlYoutube"
                disabled
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="URL Atual"
                onBlur={handleBlur}
                value={live ? live[0].link_game : values.urlGame}
                name="urlGame"
                disabled
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
          </form>
        )}
      </Formik>
      <br />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    onBlur={handleBlur}
                    value={values.available}
                    name="available"
                    color="info"
                  />
                }
                label="Disponível para todos"
                error={touched.available && !!errors.available}
                helperText={touched.available && errors.available}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="URL Youtube"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.urlYoutube}
                name="urlYoutube"
                error={!!touched.urlYoutube && !!errors.urlYoutube}
                helperText={touched.urlYoutube && errors.urlYoutube}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="URL Game"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.urlGame}
                name="urlGame"
                error={!!touched.urlGame && !!errors.urlGame}
                helperText={touched.urlGame && errors.urlGame}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Cadastrar URLS
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  urlYoutube: yup
    .string()
    .url("URL inválida")
    .required("URL do YouTube é obrigatória"),
  urlGame: yup
    .string()
    .url("URL inválida")
    .required("URL do Game é obrigatória"),
});

export default Form;
