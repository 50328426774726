import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Perguntas Frequentes" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
           Consigo visualizar meus ganhos?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Sim, na tela de Dashboard você consegue visualizar todas as informações e valores. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Consigo Tornar um User Pro?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Sim, você mesmo consegue setar um User Pro utilizando a tela de E-mails!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
           Consigo criar um Usuário?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Consegue criar atráves da tela de "Criar Usuario".
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Consigo alterar as lives e iframe do jogo?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Sim, você consegue fazer isso e alterar de acordo com o seu planejamento! Só ir na tela de "Lives"
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </Box>
  );
};

export default FAQ;
