import React, { useState, useEffect, useContext } from "react";

import BannerOptions from "./bannerOptions";
import {
  Container,
  CategoryName,
  EditIcon,
  GlobalStyle,
  AdditionalText,
  EmailTextContainer,
  CarouselContainer,
  BannerItem,
  BannerImage,
  BannerContainer,
  Input,
  Button,
  InputText,
  StyledButton,
  ButtonFechar,
  Center
} from "./styles";
import {
  Snackbar,
  Box
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import api from "../../../config/api";
import { toast } from "react-toastify";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { AuthContext, } from "../../../context/auth";


const Classes = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [bannerOptionsVisible, setBannerOptionsVisible] = useState([]);
  const currentIndexComecePorAqui = 0;
  const [classes, setClasses] = useState([]);
  const [classesCategory, setClassesCategory] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [editedCategoryNameVerify, setEditedCategoryNameVerify] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [buttonCategory, setButtonCategory] = useState(false);
  const [novaCategory, setNovaCategory] = useState("");

  useEffect(() => {
    async function getClassesCategory() {
      await api.get("/classesCategory", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((response) => {
          setClassesCategory(response.data)
          setEditedCategoryNameVerify(response.data);
        })
    }

    async function getClasses() {
      await api.get("/classes", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }).then((response) => {
        setClasses(response.data);
      })
    }
    getClassesCategory()
    getClasses()
  }, [user.token]);


  const showBannerOptions = (index) => {
    setBannerOptionsVisible((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = true;
      return updatedOptions;
    });
  };

  const handleEditClick = (bannerId) => {
    if (bannerId) {
      navigate(`/updateclasses/${bannerId}`);
    }
  };

  const handleNewGameClick = () => {
    navigate("/newclasses");
  };

  const hideBannerOptions = (index) => {
    setBannerOptionsVisible((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = false;
      return updatedOptions;
    });
  };


  async function deleteClasses(id) {
    try {
      await api.delete("/classes/delete", {
        data: { id: id },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }).then((response) => {
        setShowSnackbar(true);
      })
    } catch (error) {
      console.error(`Erro ao deletar banner com ID ${id}: ${error.message}`);
    }
  }

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
 
  const activateEditMode = (categoryName, categoryId) => {
    setEditMode(true);
    setEditedCategoryName(categoryName); console.log(categoryName)
    setSelectedCategoryId(categoryId);
  };

  // Função para desativar o modo de edição
  const deactivateEditMode = () => {
    setEditMode(false);
    setEditedCategoryName("");
    setSelectedCategoryId(null);
  };
  const updateCategory = async (categoryId, newName) => {
    try {
      await api.put(`classesCategory/update`, {
        id: categoryId,
        title: newName,
      });

      toast.success('Título atualizado com sucesso!', {
        autoClose: 2000, 
      });

      window.location.reload();
    } catch (error) {
      console.error("Erro ao atualizar a categoria:", error);
    }
  };

  async function handleCategory() {
    if (!novaCategory) {
      toast.error("Preencha o campo")
    } else {
      await api.post("/classesCategory", {
        title: novaCategory
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }).then((response) => {
        toast.success("Categoria adicionada com sucesso!")
        window.location.reload();
      }).catch((error) => {
        console.error("Error" + error.message);
      })
    }
  }

  return (
    <>
      <GlobalStyle />
      <Container>
        <AdditionalText>
          TORNE-SE UM JUSTICEIRO <br />
        </AdditionalText>
        <EmailTextContainer className="elementor-1137 elementor-element elementor-element-a04ad91">
          Aulas Disponíveis...
        </EmailTextContainer>
        <Box mt="20px"></Box>

        <StyledButton onClick={handleNewGameClick}>ADICIONAR AULAS</StyledButton>

        <StyledButton onClick={() => setButtonCategory(!buttonCategory)}>

          {!buttonCategory ? (
            "NOVA CATEGORIA"
          ) : (
            <>
              <FontAwesomeIcon icon={faTimes} />
            </>
          )}
        </StyledButton> <Box mt="20px"></Box>
        {
          buttonCategory ? (
            <>
              <Input
                placeholder="Digite um nome que deseja adicionar"
                type="text"
                value={novaCategory}
                onChange={(e) => setNovaCategory(e.target.value)}
              />

              <Button onClick={() => handleCategory()}>Salvar</Button>
            </>
          ) : null
        }
        
        {classesCategory &&
          classesCategory.map((element, item) => (
            <div key={element.id}>
              {/* Exibe o nome da categoria com ou sem campo de edição */}
              {editMode && selectedCategoryId === element.id ? (
                <Center>
                  <InputText
                    type="text"
                    value={editedCategoryName}
                    onChange={(e) => setEditedCategoryName(e.target.value)}

                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // Verifique se a tecla pressionada é "Enter" e chame a função de atualização
                        if (editedCategoryName === editedCategoryNameVerify[item].title) {
                          // toast.info("Não foi alterado nada!");
                          deactivateEditMode();
                        } else {
                          updateCategory(element.id, editedCategoryName);
                        }
                      } else if (e.key === "") {
                        deactivateEditMode();
                      }
                    }
                    }
                  />
                  <ButtonFechar onClick={()=>setEditMode(false)}> X </ButtonFechar>
                </Center>
              ) : (
                <>
                  {/* Mostra o nome da categoria como um link clicável */}
                  <CategoryName
                    onClick={() => activateEditMode(element.title, element.id)}
                  >
                    {element.title}
                    <EditIcon
                      icon={faEdit}
                      onClick={() => activateEditMode(element.title, element.id)}
                    />
                  </CategoryName>
                </>
              )}

              <CarouselContainer
                style={{
                  transform: `translateX(-${currentIndexComecePorAqui * 100}%)`,
                }}
              >
                <BannerContainer>
                  {classes &&
                    classes.map((banner, index) => (
                      classesCategory ? (
                        banner.classeCategory === classesCategory[item].id ? (
                          <BannerItem key={index}
                            onMouseEnter={() => showBannerOptions(index)}
                            onMouseLeave={() => hideBannerOptions(index)}
                          >
                            {bannerOptionsVisible[index] && (
                              <BannerOptions
                                onEditClick={handleEditClick} // Passa a função de edição
                                onDeleteClick={() => deleteClasses(banner.id)}
                                bannerId={banner.id} // Passa o ID do banner
                              />
                            )}
                            <BannerImage src={banner.banner} alt={`Banner ${index + 1}`} />
                          </BannerItem>
                        ) : null
                      ) : null
                    ))}
                </BannerContainer>
              </CarouselContainer>
            </div>
          ))}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="Jogo excluído com sucesso."
        />
      </Container>
    </>
  );
};

export default Classes;
