import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import api from "../../../config/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/auth";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../../components/BannerUpload";
import { storagePhotos } from "../../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { StyledButton } from "./styles";

const Aulas = () => {
  const navigate = useNavigate();

  const [available, setAvailable] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { user } = useContext(AuthContext);
  const [classesCategory, setClassesCategory] = useState([]);
  const [linkBanner, setLinkBanner] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getClassesCategory() {
      await api
        .get("/classesCategory", {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setClassesCategory(response.data);
        });
    }
    getClassesCategory();
  });

  const handleBanner = (event) => {
    setLinkBanner(event.target.value);
  };

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
      } else {
        toast.error("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };

  async function handleUpload() {
    setLoading(true);

    if (uploadedImage) {
      const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
      await uploadBytes(storageRef, uploadedImage).then(async (snapshot) => {
        await getDownloadURL(ref(storagePhotos, `images/${uploadedImage.name}`))
          .then(async (url) => {
            try {
              if (
                url === "" ||
                linkBanner === "" ||
                selectedCategory === "" ||
                available === ""
              ) {
                toast.info("Preencha todos os campos!");
                setLoading(false);
              } else {
                await api
                  .post(
                    "/classes",
                    {
                      banner: url,
                      link_youtube: linkBanner,
                      classeCategory: selectedCategory,
                      available: available,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + user.token,
                      },
                    }
                  )
                  .then((response) => {
                    toast.success("Aula adicionada com sucesso!");
                    setLoading(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  });
              }
            } catch (error) {
              console.log(error.message);
              toast.error("Erro ao carregar a aula... tente novamente");
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      });
    } else {
      toast.error("Insira um banner");
      setLoading(false);
    }
  }

  const handleNewGameClick = () => {
    navigate("/classes");
  };

  return (
    <Box m="20px">
      <Header
        title="Aulas NeoBet"
        subtitle="Altere os banners e adicione o link das aulas!"
      />

      <StyledButton onClick={handleNewGameClick}>VOLTAR</StyledButton>

      <Box mt="20px"></Box>
      <Formik
        onSubmit={() => {}}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <InputLabel>Categoria que deseja inserir</InputLabel>
              <Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)} // Update selected category
              >
                {classesCategory.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt="20px"></Box>

            <FormControl fullWidth margin="normal">
              <InputLabel id="include-in-home-label">Liberar aula? </InputLabel>
              <Select
                labelId="include-in-home-label"
                id="include-in-home-select"
                value={available} // Use selectPro state
                onChange={(e) => setAvailable(e.target.value)} // Update selectPro state
              >
                <MenuItem value={true}>Sim</MenuItem>{" "}
                <MenuItem value={false}>Não</MenuItem>{" "}
              </Select>
            </FormControl>
            <Box mt="20px"></Box>

            <Box display="flex" alignItems="center" gap={2}>
              <ImageUpload onImageUpload={handleImageUpload} />
              {uploadedImage && (
                <Box>
                  <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt="Uploaded"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </Box>
              )}
            </Box>

            <br></br>

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Link da Aula"
              onBlur={handleBlur}
              onChange={handleBanner}
              value={linkBanner}
              name="videoLink"
              sx={{ gridColumn: "span 4" }}
            />

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                onClick={() => handleUpload()}
                color="secondary"
                variant="contained"
              >
                {loading ? "Carregando..." : "Salvar"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  telefone: yup
    .string()
    .min(11)
    .max(11)
    .matches(phoneRegExp, "Número de telefone inválido")
    .required("Telefone é obrigatório"),
  videoLink: yup.string().required("Link da Aula é obrigatório"),
});

const initialValues = {
  email: "",
  telefone: "",
  videoLink: "",
  nome: "",
};

export default Aulas;
