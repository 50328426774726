import { Container, LoadingSpinner } from "./styles";
const Loading = () => {
  return (
    <Container>
      <LoadingSpinner />
    </Container>
  );
};

export default Loading;
