import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header";
import ImageUpload from "../../../components/ImageUpload";

import { AuthContext } from "../../../context/auth";

import { storagePhotos } from "../../../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import api from "../../../config/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Form = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [analyst, setAnalyst] = useState([]);
  const [selectedAnalyst, setSelectedAnalyst] = useState(""); 
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryNumber, setSelectedCategoryNumber] = useState(1);
  const [selectedGame, setSelectedGame] = useState("");
  const [iframeUrl, setIframeUrl] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valuesInitial, setValuesInitial] = useState();
  const [includeInHomeValue, setIncludeInHomeValue] = useState(0);
  const [selectPro, setSelectPro] = useState(false); 

  useEffect(() => {
    async function getGame() {
      try {
        await api
          .get("/games/", {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            const games = response.data;

            games.forEach((element) => {
              if (element.id === id) {
                setValuesInitial(element);
              }
            });
          });
      } catch (error) {
        console.error("Erro ao buscar jogo:", error);
      }
    }

    getGame(id);
  }, [id, user.token]);

  useEffect(() => {
    async function getAnalysts() {
      try {
        await api
          .get("/analyst", {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            setAnalyst(response.data);
          });
      } catch (error) {
        console.error("Erro ao buscar Analista:", error);
      }
    }

    getAnalysts(id);
  }, [id, user.token]);

  const handleImageUpload = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (
        image.type === "image/jpeg" ||
        image.type === "image/png" ||
        image.type === "image/webp"
      ) {
        setUploadedImage(image);
      } else {
        alert("Envie uma imagem PNG ou JPEG");
        setUploadedImage(null);
        return null;
      }
    }
  };
  const categories = {
    Crash: [
      "Aviator",
      "Aviator2x",
      "AviatorVelasAltas",
      "Spaceman",
      "Spaceman2x",
      "SpacemanVelasAltas",
    ],
    Cassino: [
      "Roleta",
      "BacBo",
      "Dragon Tiger",
      "Crazy Time",
      "Football Studio",
    ],
    Slots: [
      "Mines",
      "Fortune Tiger",
      "Fortune Rabbit",
      "Fortune OX",
      "Fortune Mouse",
    ],
  };

  function SelectCategory(select) {
    if (select === "Crash") {
      setSelectedCategoryNumber(2);
    } else if (select === "Cassino") {
      setSelectedCategoryNumber(1);
    } else if (select === "Slots") {
      setSelectedCategoryNumber(3);
    }

    return selectedCategoryNumber;
  }

  async function handleUpload() {
    setLoading(true);

    if (uploadedImage) {
      const storageRef = ref(storagePhotos, `images/${uploadedImage.name}`);
      await uploadBytes(storageRef, uploadedImage).then(async (snapshot) => {
        await getDownloadURL(ref(storagePhotos, `images/${uploadedImage.name}`))
          .then(async (url) => {
            try {
              if (
                url === "" ||
                selectedCategory === "" ||
                selectedGame === "" ||
                iframeUrl === "" ||
                includeInHomeValue === ""
              ) {
                toast.info("Preencha todos os campos...");
                setLoading(false);
              } else {
                SelectCategory(selectedCategory);
                await api
                  .post(
                    "/games",
                    {
                      all: selectPro,
                      gameImg: url,
                      name: selectedGame,
                      home: includeInHomeValue,
                      link_iframe: iframeUrl,
                      analyst: selectedAnalyst,
                      category: selectedCategoryNumber,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + user.token,
                      },
                    }
                  )
                  .then((response) => {
                    toast.success("Jogo adicionado!");
                    setLoading(false);
                    window.location.reload();
                  })
                  .catch((error) => {
                    console.log("Aqui", error);
                    setLoading(false);
                  });
              }
            } catch (error) {
              console.log(error);
              toast.info("Carregamento em andamento... tente novamente");
              setLoading(false);
            }

            setImageUrl(url);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
      });
    } else {
      toast.warn("Insira um banner!");
      setLoading(false);
    }
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    SelectCategory(event.target.value);
    console.log(selectedCategoryNumber);
    setSelectedGame("");
  };

  return (
    <Box m="20px">
      <Header
        title="CRIANDO JOGO"
        subtitle="Crie um jogo de uma maneira bem mais simples"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="category-select-label">Categorias</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={selectedCategory}
          label="Categorias"
          onChange={handleCategoryChange}
        >
          {Object.keys(categories).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" disabled={!selectedCategory}>
        <InputLabel
          htmlFor="game-select"
          style={{ visibility: selectedGame ? "hidden" : "visible" }}
        >
          Nome do Jogo
        </InputLabel>
        <TextField
          id="game-select"
          value={selectedGame}
          label=""
          onChange={(e) => setSelectedGame(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-select-label">
            Selecione o Analista
          </InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedAnalyst} 
            onChange={(e) => setSelectedAnalyst(e.target.value)}
          >
            {analyst.map((analystItem) => (
              <MenuItem key={analystItem.id} value={analystItem.id}>
                {analystItem.name} ({analystItem.game})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="include-in-home-label">
            Game disponivel para todos?
          </InputLabel>
          <Select
            labelId="include-in-home-label"
            id="include-in-home-select"
            value={selectPro} 
            onChange={(e) => setSelectPro(e.target.value)}
          >
            <MenuItem value={true}>Sim</MenuItem>{" "}
            <MenuItem value={false}>Não</MenuItem>{" "}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="include-in-home-label">
            Adicionar jogo na home?
          </InputLabel>
          <Select
            labelId="include-in-home-label"
            id="include-in-home-select"
            value={includeInHomeValue} 
            onChange={(e) => setIncludeInHomeValue(e.target.value)} 
          >
            <MenuItem value={1}>Sim</MenuItem>{" "}
            <MenuItem value={0}>Não</MenuItem>{" "}
          </Select>
        </FormControl>
      </FormControl>

      <Box mt="20px">
        <Box display="flex" alignItems="center" gap={2}>
          <ImageUpload onImageUpload={handleImageUpload} />
          {uploadedImage && (
            <Box>
              <Typography variant="body2">{uploadedImage.name}</Typography>{" "}
              <img
                src={URL.createObjectURL(uploadedImage)}
                alt="Uploaded"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            </Box>
          )}
        </Box>

        <FormControl fullWidth margin="normal">
          <TextField
            fullWidth
            variant="outlined"
            label={`Coloque a URL do jogo [${selectedGame}]`}
            value={iframeUrl}
            onChange={(e) => setIframeUrl(e.target.value)}
          />
          <Box display="flex" justifyContent="center" mt="20px">
            <Button
              onClick={() => handleUpload()}
              type="submit"
              color="secondary"
              variant="contained"
            >
              {loading ? "Carregando..." : "Cadastrar"}
            </Button>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Form;
