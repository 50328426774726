import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Topbar from "./topbar"; // Importe o componente Topbar local
import api from "../../config/api";
import { AuthContext } from "../../context/auth";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50px", // Para tornar o botão redondo
    // Cor da borda do botão
    backgroundColor: "transparent", // Fundo transparente
    color: theme.palette.primary.main, // Cor do texto do botão
    "&:hover": {
      backgroundColor: theme.palette.primary.main, // Cor de fundo ao passar o mouse
      color: theme.palette.common.white, // Cor do texto ao passar o mouse
    },
  },
}));
const Team = () => {
   // Estado para armazenar os dados do gráfico
   const [chartData, setChartData] = useState({
    datasets: [],
  });

  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const { user } = useContext(AuthContext);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [error, setError] = useState(null);

  useEffect(() => {
    
    async function getUsers() {
      try {
        const response = await api.get("/users", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }
    getUsers();
  }, [user.token]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "telefone",
      headerName: "Telefone",
      flex: 1,
    },
    {
      field: "premiumRentCount",
      headerName: "Permissão",
      flex: 1,
      renderCell: ({ row }) => {
        const handleMakePro = async () => {
          const confirmMakePro = window.confirm(
            `Deseja adicionar ${row.name} para usuário Premium?`
          );

          if (confirmMakePro) {
            try {
              // Fazer a solicitação POST para atualizar o usuário como "premium_rent"
              await api
                .post(
                  "/premium/",
                  {
                    email: row.email,
                    name: row.name,
                    telefone: row.telefone,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  }
                )
                .then((res) => {
                  toast.success("Usuário Premium ⭐");
                  setTimeout(() => {
                    window.location.reload()
                  }, 3000)
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (error) {
              console.error("Erro ao atualizar o usuário: ", error);
              setError(error);
            }
          }
        };

        const handleMakeUser = async () => {
          const confirmMakePro = window.confirm(
            `Deseja adicionar ${row.name} para usuário?`
          );

          if (confirmMakePro) {
            try {
              // Fazer a solicitação DELETE para atualizar o usuário como "premium_rent"
              await api
                .post(
                  "/premium/delete",
                  {
                    email: row.email,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  }
                )
                .then((res) => {
                  toast.success("Usuário Normal ⭐");
                  setTimeout(() => {
                    window.location.reload()
                  }, 3000)
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (error) {
              console.error("Erro ao atualizar o usuário: ", error);
              setError(error);
            }
          }
        };

        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"

            borderRadius="14px"
          >
            {row.premiumRentCount === "pro" && (
              <>
                <Button
                  className={classes.button} // Aplica os estilos personalizados
                  variant="contained"
                  color="primary"
                  onClick={handleMakeUser}
                  style={{ backgroundColor: '#246e8b', borderRadius: "50px", border: `2px solid ${theme.palette.primary.main}` }}
                >
                  Pro
                </Button>

              </>
            )}



            {row.premiumRentCount !== "pro" && (
              <Button
                variant="contained"
                onClick={handleMakePro}
                style={{ backgroundColor: '#1b180b', borderRadius: "50px", border: `2px solid ${theme.palette.primary.main}` }}
              >
                user
              </Button>

            )}
          </Box>
        );
      },
    },
  ];

  const handleSearch = (searchValue) => {
    const filtered = users.filter((user) =>
      user.email.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  return (
    <Box m="20px">
    <Header
      title="LISTA DE USUÁRIOS"
      subtitle="Lista com todos os usuários"
    /><Topbar onSearch={handleSearch} />
    <Box
      m="40px 0 0 0"
      height="75vh"
      
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
        <DataGrid rows={filteredUsers} columns={columns} components={{ Toolbar: GridToolbar }}/>
        
      </Box>
    </Box>
  );
};

export default Team;