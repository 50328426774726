import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import api from "../../config/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth";
import { useContext } from "react";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {user} = useContext(AuthContext);
  const handleFormSubmit = async (values) => {
    try {
      await api.post("/users", {
        email: values.email,
        name: values.nome,
        telefone: values.telefone,
      },
      {
        headers: {
        'Authorization': 'Bearer ' + user.token
      }
    });
      toast.success("Cadastrado Com Sucesso!!!");
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        toast.error("Erro ao cadastrar usuário. Verifique os dados e tente novamente.");
      } else if (error.request) {
        console.error("No Response:", error.request);
        toast.error("Não foi possível conectar ao servidor. Tente novamente mais tarde.");
      } else {
        console.error("Error:", error.message);
        toast.error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
      }
    }
  };
  

  return (
    <Box m="20px">
      <Header title="CRIANDO USUÁRIO" subtitle="Criando um novo usuário na NeoBet" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nome}
                name="nome"
                error={!!touched.nome && !!errors.nome}
                helperText={touched.nome && errors.nome}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Telefone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.telefone}
                name="telefone"
                error={!!touched.telefone && !!errors.telefone}
                helperText={touched.telefone && errors.telefone}
                sx={{ gridColumn: "span 4" }}
                
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Criar Usuario
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  telefone: yup
    .string().min(11).max(11)
    .matches(phoneRegExp, "Número de telefone inválido")
    .required("Telefone é obrigatório"),
  nome: yup.string().required("Nome é obrigatório"),
});
const initialValues = {
  email: "",
  telefone: "",
  nome: "",
};

export default Form;
