import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from "@mui/material";
import {
CardWrapper,  CardsContainer, StyledButton
} from "./styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import Cards from "../../../components/index";
import { useNavigate } from "react-router-dom";
import api from "../../../config/api";
import { AuthContext } from "../../../context/auth";

const GameList = () => {
  const { user } = useContext(AuthContext);
  const [gamesApi, setGamesApi] = useState([]);
  const categories = ["Home", "Crash", "Cassino", "Slots"];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("Home");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getGames() {
      await api
        .get("/games", {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((res) => {
          setGamesApi(res.data);
        });
    }
    getGames();
  }, [user.token]);

  async function handleDeleteGame(gameId) {
    await api
      .post(
        "/games/delete",
        {
          id: gameId,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((res) => {
        setShowSnackbar(true);
        const updatedGames = gamesApi.filter((game) => game.id !== gameId);
        setGamesApi(updatedGames);
      })
      .catch((error) => {
        console.error("Erro ao excluir o jogo:", error);
      });
  }

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (category) => {
    setAnchorEl(null);
    setSelectedCategory(category);
  };

  const handleNewGameClick = () => {
    navigate("/newgame");
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Lista de Jogos</Typography>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleFilterClose(selectedCategory)}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              onClick={() => handleFilterClose(category)}
            >
              {category}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <StyledButton onClick={handleNewGameClick}>NOVO GAME</StyledButton>

      <CardsContainer>
        {selectedCategory === "Home"
          ? gamesApi &&
            gamesApi.map((game) =>
              game.home === 1 ? (
                <CardWrapper key={game.id}>
                  <Cards
                    Pro={false}
                    CardImg={game.gameImg}
                    LinkGame={`/updategames/${game.id}`}
                    onDeleteGame={() => handleDeleteGame(game.id)}
                  />
                </CardWrapper>
              ) : null
            )
          : null}
        {selectedCategory === "Cassino"
          ? gamesApi &&
            gamesApi.map((game) =>
              game.category === 1 ? (
                <CardWrapper key={game.id}>
                  <Cards
                    Pro={false}
                    CardImg={game.gameImg}
                    LinkGame={`/updategames/${game.id}`}
                    onDeleteGame={() => handleDeleteGame(game.id)}
                  />
                </CardWrapper>
              ) : null
            )
          : null}
        {selectedCategory === "Crash"
          ? gamesApi &&
            gamesApi.map((game) =>
              game.category === 2 ? (
                <CardWrapper key={game.id}>
                  <Cards
                    Pro={false}
                    CardImg={game.gameImg}
                    LinkGame={`/updategames/${game.id}`}
                    onDeleteGame={() => handleDeleteGame(game.id)}
                  />
                </CardWrapper>
              ) : null
            )
          : null}

        {selectedCategory === "Slots"
          ? gamesApi &&
            gamesApi.map((game) =>
              game.category === 3 ? (
                <CardWrapper key={game.id}>
                  <Cards
                    Pro={false}
                    CardImg={game.gameImg}
                    LinkGame={`/updategames/${game.id}`}
                    onDeleteGame={() => handleDeleteGame(game.id)}
                  />
                </CardWrapper>
              ) : null
            )
          : null}
      </CardsContainer>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Jogo excluído com sucesso."
      />
    </Box>
  );
};

export default GameList;
