import React, { useState, useEffect, useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";

import BannerOptions from "../classes/classes/bannerOptions";
import { Container, ButtonClose, ButtonDiv } from "./styles";
import {
  Snackbar,
} from "@mui/material";

import Banner1 from '../../assets/Tutorial/Banner1.png'
import Banner2 from '../../assets/Tutorial/Banner2.png'
import Banner3 from '../../assets/Tutorial/Banner3.webp'
import gestao from '../../assets/Tutorial/Gestao.png'
import aprendaBacbo from '../../assets/Tutorial/AprendaBacbo.webp'
import aprendaSpaceMan from '../../assets/Tutorial/AprendaSpaceman.webp'
import aprendaFootball from '../../assets/Tutorial/AprendaFootball.webp'
import THUMB2 from '../../assets/Tutorial/THUMB2.png'
import THUMB3 from '../../assets/Tutorial/THUMB3.png'
import THUMB4 from '../../assets/Tutorial/THUMB4.png'
import { useNavigate } from "react-router-dom";
import api from "../../config/api";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext, } from "../../context/auth";

const AdditionalText = styled.div`
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  color: #fff;
  font-family: ubuntu, Sans-serif;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 80px;
  text-transform: uppercase;
  line-height: 1.3em;
  z-index: 2;
  @media (max-width: 768px) {
    padding: 1px;
    text-align: center;
    background-size: 100%;
    background-position: center;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7px;
  margin-top: 5px;
`;

const TextoPequeno = styled.label`
  display: flex;
  padding-left: 12px;
  margin-right: 50%;
  margin-top: 20px;
  color: #DCDDDF;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 10px;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  width: 100%;
  max-height: 600px;
  min-height: 1px;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

const BannerItem = styled.div`
  flex: 0 0 auto;
  scroll-snap-align: start;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
`;

const BannerImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 130px;
  border-radius: 10px;
  margin-left: 5px;
`;

const BannerImage2 = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 150px;
  border-radius: 10px;
  margin-left: 5px;
`;

const AccessButton = styled.button`
  background-image: url(/wp-content/uploads/2023/08/acessar-login.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #14b1a4;
  border: none;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #14b1a4;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const EmailTextContainer = styled.div`
  color: #14b1a4;
  font-family: Verdana, Sans-serif;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  text-align: center;
`;

const PaginationDot = styled.span`
  width: 5px;
  height: 5px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? "#14b1a4" : "#fff")};
  border-radius: 50%;
  cursor: pointer;
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
`;

const VideoContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 340px;
`;

const Tutorial = () => {
  const [bannerOptionsVisible, setBannerOptionsVisible] = useState([]); // Estado para controlar as opções de banner
  const [currentIndexComecePorAqui, setCurrentIndexComecePorAqui] = useState(0);
  const [currentIndexMentalidadeEGestao, setCurrentIndexMentalidadeEGestao] = useState(0);
  const [currentIndexComoJogar1, setCurrentIndexComoJogar1] = useState(0);
  const [currentIndexComoJogar2, setCurrentIndexComoJogar2] = useState(0);
  const [selectedBannerId, setSelectedBannerId] = useState(null);
  const { user } = useContext(AuthContext);
  const [classes, setClasses] = useState([]);
  const [classesCategory, setClassesCategory] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const bannersComecePorAqui = [
    {
      image: Banner1,
    },
    {

      image: Banner2,
    },
    {

      image: Banner3,
    },
  ];

  const bannersMentalidadeEGestao = [
    {

      image: gestao,
      // videoId: "8YvdbPJlw9I",
    },
  ];

  const bannersComoJogar1 = [
    {

      image: aprendaBacbo,
      // videoId: "8YvdbPJlw9I",
    },
    {

      image: aprendaSpaceMan,
      // videoId: "8YvdbPJlw9I",
    },
    {

      image: aprendaFootball,
      // videoId: "8YvdbPJlw9I",
    },
  ];

  const bannersComoJogar2 = [
    {

      image: THUMB2,
      // videoId: "VIDEO_ID_GESTAO_2",
    },
    {

      image: THUMB3,
      // videoId: "VIDEO_ID_GESTAO_2",
    },
    {

      image: THUMB4,
      // videoId: "VIDEO_ID_GESTAO_2",
    },
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const openModal = (videoId) => {
    setSelectedVideoId(videoId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVideoId(null);
    setModalIsOpen(false);
  };

  // Função para mostrar as opções de editar e excluir para um banner específico
  const showBannerOptions = (index) => {
    setBannerOptionsVisible((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = true;
      return updatedOptions;
    });
  };

  const navigate = useNavigate();

  const handleEditClick = (bannerId) => {
    // Verifique se o bannerId é válido antes de navegar para a página de edição
    if (bannerId) {
      navigate(`/updateHome/${bannerId}`);
    }
  };

  // Função para ocultar as opções de editar e excluir para um banner específico
  const hideBannerOptions = (index) => {
    setBannerOptionsVisible((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = false;
      return updatedOptions;
    });
  };

  useEffect(() => {
    async function getClasses() {
      await api.get("/home", {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }).then((response) => {
        setClasses(response.data);
      })
    }
    getClasses()
  }, [user.token]);
  

  useEffect(() => {
    async function getClassesCategory() {
      await api.get("/classesCategory", {
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then((response) => {
          setClassesCategory(response.data)
        })
    }
    getClassesCategory()
  }, [user.token]);

  async function deleteClasses(id) {
    try {
      await api.delete("/home/delete", {
        data: { id: id }, // Passa o ID no corpo da requisição
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      }).then((response) => {
        setShowSnackbar(true);
      })
    } catch (error) {
      console.error(`Erro ao deletar banner com ID ${id}: ${error.message}`);
    }
  }

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  return (
    <>
      <GlobalStyle />

      <Container>
        <AdditionalText>
          BANNERS DA TELA HOME <br />
        </AdditionalText>

        <EmailTextContainer className="elementor-1137 elementor-element elementor-element-a04ad91">
          Faça as alterações que desejar!
        </EmailTextContainer>

        <TextoPequeno>
          Banners Inicial
        </TextoPequeno>
        <CarouselContainer
          style={{ transform: `translateX(-${currentIndexComecePorAqui * 100}%)` }}
        >
          <BannerContainer>
            {classes.map((banner, index) => (
              <BannerItem
                key={index}
                onMouseEnter={() => showBannerOptions(index)}
                onMouseLeave={() => hideBannerOptions(index)}
              >
                {bannerOptionsVisible[index] && (
                  <BannerOptions
                    onEditClick={handleEditClick}
                    onDeleteClick={() => deleteClasses(banner.id)}
                    bannerId={banner.id}
                  />
                )}
                <BannerImage src={banner.banner} alt={`Banner ${index + 1}`} />
              </BannerItem>
            ))}
          </BannerContainer>
        </CarouselContainer>


        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="Jogo excluído com sucesso."
        />
      </Container>
    </>
  );
};

export default Tutorial;
