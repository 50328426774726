import { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";

import api from "../config/api";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    async function loadStorage() {
      const storageUser = localStorage.getItem("SistemaUser");

      if (storageUser) {
        setLoading(true);
        await api
          .post("/users/token", {
            token: JSON.parse(storageUser).token,
          })
          .then((response) => {
            if (response.data.valid) {
              setUser(JSON.parse(storageUser));
              setLoading(false);
            } else {
              logoff();
              setLoading(false);
            }
          });
      }
      setLoading(false);
    }
    loadStorage();
  }, []);

  async function login(email, password) {
    setLoading(true);

    await api
      .post("/admin/login", {
        email: email,
        pass: password,
      })
      .then((response) => {
        setUser(response.data);
        var objetoJSONString = JSON.stringify(response.data);

        localStorage.setItem("SistemaUser", objetoJSONString);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        error && toast.error("Email não cadastrado!");
      });
  }

  async function logoff() {
    setUser(null);
    localStorage.removeItem("SistemaUser");
  }

  return (
    <AuthContext.Provider value={{ user, loading, login, logoff }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
